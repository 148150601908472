<template>
    <div class="tile is-ancestor box">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-8">
            <article class="tile is-child notification is-dark box">
              <p class="title">Philippine equities market</p>
              <p class="subtitle"><router-link to="/register">Sign up</router-link>  or <router-link to="/login">Login</router-link>
                for a better view</p>
              <div class="columns ">
                <div class="column" v-for="(card) in cards" :key="card.id">
                  <div class="column">
                    <carousel-card :percent-change="card.percentChange" :symbol="card.symbol"
                                   :name="card.name" :timestamp="card.timestamp"/>
                  </div>

                </div>
              </div>
            </article>

          </div>
          <div class="tile is-parent is-4">
            <article class="tile is-child notification is-gray">
              <p class="title">Welcome to the new look and feel</p>
              <p class="subtitle has-text-left">{{ welcome }}</p>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div class="tile is-ancestor box">
      <div class="tile is-vertical is-parent pb-lg-4 ">
        <div class="tile">
          <article class="tile is-child notification is-info">
            <p class="title">The Anti-fiat market glimpse</p>
            <div class="columns">
              <div class="column">
                <article class="notification is-info">
                  <p class="title">{{ gold.price }}</p>
                  <p class="subtitle"> {{ gold.name }}</p>
                </article>
              </div>
              <div class="column">
                <article class="notification is-info">
                  <p class="title">{{ silver.price }}</p>
                  <p class="subtitle"> {{ silver.name }}</p>
                </article>
              </div>
              <div class="column">
                <article class="notification is-info">
                  <p class="title">{{ bitcoin.price }}</p>
                  <p class="subtitle"> {{ bitcoin.name }}</p>
                </article>
              </div>
            </div>
          </article>

        </div>
      </div>
    </div>
</template>

<script>
import CarouselCard from "@/components/CarouselCard";
import { getFirstThreeStocks } from "@/js/pse_stocks"
import { getBitcoinPrice} from "@/js/bitcoin";
import { getGoldAndSilverPrices } from "@/js/metal_prices";

export default {
  name: "MainTilePanel",
  components: {CarouselCard},
  created() {
    this.getStockData()
    this.getBitcoinPrice();
    this.getGoldAndSilverPrices();
    setInterval(this.getStockData, 10 * 1000);
    setInterval(this.getBitcoinPrice, 10 * 1000);
    //24 hours interval to ms = 86400000
    //setInterval(this.getGoldAndSilverPrices, 86400000);
  },
  data() {
    return {
      gold: {
        price: '$0',
        name: 'Gold (per troy ounce)'
      },
      silver: {
        price: '$0',
        name: 'Silver (per troy ounce)'
      },
      bitcoin: {
        price: '$24,000',
        name: 'Bitcoin'
      },
      welcome: 'Still unmatched, still unbeaten. Our new look and feel gives you a better view on what is actually ' +
        'going on in the world today. As we further improve and expand along the way, please take a moment to ' +
        'the basics on how this system works!',
      cards: [
        {
          percentChange: '10%',
          symbol: 'ICT',
          name: 'International Container Terminal Services, Inc.',
          timestamp: new Date().toDateString(),
        },
        {
          percentChange: '5%',
          symbol: 'BPI',
          name: 'Bank of the Philippine Islands',
          timestamp: new Date().toDateString(),
        },
        {
          percentChange: '-15%',
          symbol: '2GO',
          name: '2GO Logistics',
          timestamp: new Date().toDateString(),
        },
      ]

    }
  },
  methods: {
    getStockData() {
      getFirstThreeStocks((data) => {
        let card = {};
        let cards = [];
        for (let i = 0; i < data.length; i++) {
          card = this.cards[i];
          console.log("first three stocks");
          console.table(card)
          card.percentChange = data[i].change + '%';
          card.symbol = data[i].symbol;
          card.name = data[i].name;
          card.timestamp = data[i].as_of
          cards.push(card);
        }
        this.cards = cards;
      });
    },
    getBitcoinPrice() {
      getBitcoinPrice((data) => {
        this.bitcoin.price = data.rate;
      })
    },
    getGoldAndSilverPrices() {
      getGoldAndSilverPrices((data) => {

        this.gold.price = data.gold
        this.silver.price = data.silver
      })
    }
  },

}
</script>

<style scoped>

</style>
